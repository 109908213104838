import request from '@/utils/request';
// const qs = require("qs");

// 语言列表
export function lan_list(data) {
  return request({
    url: '/app/langList',
    method: 'get',
    params: data
  });
}