export default {
  namespaced: true,
  state: function state() {
    return {
      visible: false,
      percent: 0
    };
  },
  mutations: {
    SET_PERCENT: function SET_PERCENT(state, params) {
      state.visible = params.visible;
      state.percent = params.percent || 0;
      if (state.percent >= 100) {
        state.visible = false;
        state.percent = 0;
      }
    }
  }
};