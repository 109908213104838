import Cookies from 'js-cookie';
var TokenKey = 'sdkx_admin_token';
var UserKey = 'sdkx_admin_user';
export function getToken() {
  return Cookies.get(TokenKey);
}
export function setToken(token) {
  console.log(token);
  return Cookies.set(TokenKey, token);
}
export function removeToken() {
  return Cookies.remove(TokenKey);
}
export function setUser(user) {
  console.log(user);
  return Cookies.set(UserKey, user);
}
export function getUser() {
  return Cookies.get(UserKey);
}
export function removeUser() {
  return Cookies.remove(UserKey);
}