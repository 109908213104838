import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedstate from 'vuex-persistedstate';
import app from "./modules/app";
import user from "./modules/user";
import books from "./modules/books";
import progress from "./modules/progress";
import feedback from "./modules/feedback";
Vue.use(Vuex);
export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    app: app,
    user: user,
    books: books,
    progress: progress,
    feedback: feedback
  },
  plugins: [createPersistedstate({
    key: 'erabbit-client-pc-store',
    // 存数据的key名   自定义的  要有语义化
    paths: ['app', 'user', 'books'] // 要把那些模块加入缓存
  })]
});