exports = module.exports = require("../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "#app{font-family:-apple-system, \"SF Pro Text\", \"Helvetica Neue\", HelveticaNeue,\r Helvetica, Arial, sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;color:#333;min-width:1366px}#nav{padding:30px}#nav a{font-weight:bold;color:#2c3e50}#nav a.router-link-exact-active{color:#42b983}*{margin:0;padding:0;-webkit-box-sizing:border-box;box-sizing:border-box}\n", ""]);

// exports
exports.locals = {
	"menuText": "#bfcbd9",
	"menuActiveText": "#409EFF",
	"subMenuActiveText": "#f4f4f5",
	"menuBg": "#304156",
	"menuHover": "#263445",
	"subMenuBg": "#1f2d3d",
	"subMenuHover": "#001528",
	"sideBarWidth": "310px",
	"clientWidth": "1366px",
	"clientMaxWidth": "1620px",
	"selectActive": "#409EFF",
	"titlePaddingTop": "20px",
	"commonPadding": "20px",
	"menuWidth": "220px"
};