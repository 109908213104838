import _objectSpread from "E:/vue/sdkxv2_manage/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es6.array.find";
import { publisher, books, version } from "@/api/bookManage";
export default {
  namespaced: true,
  state: function state() {
    return {
      type: "card",
      publisher: {},
      publisherList: [],
      versionList: [],
      version: {},
      typeList: {
        card: 10,
        image: 20,
        erCode: 30,
        demonstrate: 40
      },
      count: {},
      showTabs: true
    };
  },
  mutations: {
    SET_LIST: function SET_LIST(state, list) {
      state.publisherList = list;
    },
    SET_TYPE: function SET_TYPE(state, type) {
      state.type = type;
    },
    SET_PUBLISHER: function SET_PUBLISHER(state, publisher) {
      state.publisher = publisher;
    },
    SET_VERSION: function SET_VERSION(state, version) {
      state.version = version;
    },
    SET_VERSION_LIST: function SET_VERSION_LIST(state, list) {
      state.versionList = list;
    },
    SET_COUNT: function SET_COUNT(state, count) {
      state.count = count;
    },
    SET_SHOWTABS: function SET_SHOWTABS(state, boo) {
      state.showTabs = boo;
    }
  },
  actions: {
    // 请求数据函数
    get_publisher_list: function get_publisher_list(_ref) {
      var commit = _ref.commit,
        state = _ref.state;
      publisher.list({
        bookType: state.typeList[state.type]
      }).then(function (res) {
        commit("SET_LIST", res.data);
        if (state.publisher) {
          var obj = res.data.find(function (k) {
            return k.publisherId == state.publisher.publisherId;
          });
          if (obj) {
            commit('SET_PUBLISHER', obj);
          }
        }
      });
    },
    get_version_list: function get_version_list(_ref2, param) {
      var commit = _ref2.commit,
        state = _ref2.state;
      // return new Promise((resolve, reject) => {
      // if (state.type == 'erCode') return
      version.list(_objectSpread({}, param)).then(function (res) {
        commit("SET_VERSION_LIST", res.data);
        commit('SET_VERSION', {});
        if (state.version.id) {
          var obj = res.data.find(function (k) {
            return k.id == state.version.id;
          });
          if (obj) {
            commit('SET_VERSION', obj);
          }
        } else {
          commit('SET_VERSION', res.data[0]);
        }
      });
      // });
    },
    get_count: function get_count(_ref3) {
      var commit = _ref3.commit,
        state = _ref3.state;
      books.count({
        bookType: state.typeList[state.type]
      }).then(function (res) {
        commit("SET_COUNT", res.data);
      });
    }
  }
};