import { login as _login, logout as _logout } from '@/api/portal';
import { getToken, setToken, removeToken, setUser, removeUser } from '@/utils/auth';
import router, { resetRouter } from '@/router';
var getDefaultState = function getDefaultState() {
  return {
    token: getToken(),
    name: '',
    avatar: '',
    info: {},
    roles: [],
    user: {}
  };
};
var state = getDefaultState();
var mutations = {
  RESET_STATE: function RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_USER: function SET_USER(state, user) {
    state.user = user;
  },
  SET_NAME: function SET_NAME(state, name) {
    state.name = name;
  },
  SET_AVATAR: function SET_AVATAR(state, avatar) {
    state.avatar = avatar;
  },
  SET_INFO: function SET_INFO(state, info) {
    state.info = info;
  },
  SET_ROLES: function SET_ROLES(state, roles) {
    state.roles = roles;
  }
};
var actions = {
  // user login
  login: function login(_ref, userInfo) {
    var commit = _ref.commit;
    var userName = userInfo.userName,
      password = userInfo.password,
      yzmCode = userInfo.yzmCode;
    return new Promise(function (resolve, reject) {
      _login({
        userName: userName.trim(),
        password: password,
        yzmCode: yzmCode
      }).then(function (response) {
        var result = response.data;
        if (response.data.code === 200) {
          commit('SET_TOKEN', "".concat(result.data.id, "_").concat(result.data.token));
          commit('SET_USER', result.data);
          setToken("".concat(result.data.id, "_").concat(result.data.token));
          setUser(result.data);
          resolve(response.data);
        } else {
          resolve(response.data);
        }
      }).catch(function (error) {
        console.log(error);
        reject(error);
      });
    });
  },
  // user logout
  logout: function logout(_ref2) {
    var commit = _ref2.commit,
      state = _ref2.state;
    return new Promise(function (resolve, reject) {
      _logout({}).then(function () {
        commit('SET_TOKEN', '');
        commit('SET_ROLES', []);
        removeToken(); // must remove  token  first
        removeUser();
        resetRouter();
        commit('RESET_STATE');
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // remove token
  resetToken: function resetToken(_ref3) {
    var commit = _ref3.commit;
    return new Promise(function (resolve) {
      removeToken(); // must remove  token  first
      removeUser();
      commit('RESET_STATE');
      resolve();
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};