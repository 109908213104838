import _typeof from "E:/vue/sdkxv2_manage/node_modules/@babel/runtime/helpers/esm/typeof.js";
import axios from 'axios';
// import { MessageBox, Message } from 'element-ui'
import { Message } from 'element-ui';
import store from '@/store';
import { getToken } from '@/utils/auth';
import cache from "./cache";

// create an axios instance
var service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 20000 // request timeout
});

// request interceptor
service.interceptors.request.use(function (config) {
  var isRepeatSubmit = (config.headers || {}).repeatSubmit === false;

  // config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
  if (store.state.user.token) {
    // let each request carry token
    // ['X-Token'] is a custom headers key
    // please modify it according to the actual situation
    config.headers['token'] = getToken();
  }

  // 是否需要防止数据重复提交
  if (!isRepeatSubmit && (config.method === 'post' || config.method === 'put')) {
    var requestObj = {
      url: config.url,
      data: _typeof(config.data) === 'object' ? JSON.stringify(config.data) : config.data,
      time: new Date().getTime()
    };
    // const requestSize = Object.keys(JSON.stringify(requestObj)).length; // 请求数据大小
    // const limitSize = 5 * 1024 * 1024; // 限制存放数据5M
    // if (requestSize >= limitSize) {
    //   console.warn(`[${config.url}]: ` + '请求数据大小超出允许的5M限制，无法进行防重复提交验证。')
    //   return config;
    // }
    var sessionObj = cache.session.getJSON('sessionObj');
    if (sessionObj === undefined || sessionObj === null || sessionObj === '') {
      cache.session.setJSON('sessionObj', requestObj);
    } else {
      var s_url = sessionObj.url; // 请求地址
      var s_data = sessionObj.data; // 请求数据
      var s_time = sessionObj.time; // 请求时间
      var interval = 1000; // 间隔时间(ms)，小于此时间视为重复提交
      if (s_data === requestObj.data && requestObj.time - s_time < interval && s_url === requestObj.url) {
        var message = '数据正在处理，请勿重复提交';
        console.warn("[".concat(s_url, "]: ") + message);
        return Promise.reject(new Error(message));
      } else {
        cache.session.setJSON('sessionObj', requestObj);
      }
    }
  }
  return config;
}, function (error) {
  // do something with request error
  console.log(error); // for debug
  return Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
 */

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
function (response) {
  var res = response.data;
  // if the custom code is not 20000, it is judged as an error.
  var code = res.code || 200;
  if (code !== 200) {
    // if (!res.msg) {
    //     return res
    // }
    Message.closeAll();
    Message({
      message: res.msg || 'Error',
      type: 'error',
      duration: 5 * 1000
    });

    // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
    if (res.code === 401) {
      setTimeout(function () {
        store.dispatch('user/resetToken').then(function () {
          location.reload();
        });
      }, 1000);
    }

    // if (res.code === '2005') {
    //   return res
    // }
    return Promise.reject(new Error(res.msg || 'Error'));
  } else {
    return res;
  }
}, function (error) {
  console.log('err' + error); // for debug
  Message.closeAll();
  Message({
    message: error.message,
    //   message: '网络异常',
    type: 'error',
    duration: 5 * 1000
  });
  return Promise.reject(error);
});
export default service;