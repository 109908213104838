import request from '@/utils/request';

// GET /feedback/list每个app的用户反馈数据
export function feedback_list(data) {
  return request({
    url: '/feedback/list',
    method: 'get',
    params: data
  });
}

// GET /feedback/{appId}获取单个app的用户反馈数据
export function feedback_detail(data) {
  return request({
    url: "feedback/".concat(data.id),
    method: 'get',
    params: data
  });
}

// GET /feedback/undoCount用户未处理信息数量
export function feedback_undoCount(data) {
  return request({
    url: '/feedback/undoCount',
    method: 'get',
    params: data
  });
}

// ------------------------我想知道-------------------------------
// GET /feedback/question/list查询提问
export function feedback_question_list(data) {
  return request({
    url: '/feedback/question/list',
    method: 'get',
    params: data
  });
}

// GET /feedback/question/{id}查询指定提问
export function feedback_question_detail(data) {
  return request({
    url: "/feedback/question/".concat(data.id),
    method: 'get',
    params: data
  });
}

// POST /feedback/answer/add回答提问
export function feedback_answer_add(data) {
  return request({
    url: "/feedback/answer/add",
    method: 'post',
    data: data
  });
}

// ------------------------商务合作-------------------------------
// GET /feedback/bizCooperate/list查询商务合作列表
export function feedback_bizCooperate_list(data) {
  return request({
    url: '/feedback/bizCooperate/list',
    method: 'get',
    params: data
  });
}

// GET /feedback/bizCooperate/{id}查询指定
export function feedback_bizCooperate_detail(data) {
  return request({
    url: "/feedback/bizCooperate/".concat(data.id),
    method: 'get',
    params: data
  });
}

// PUT /feedback/bizCooperate/update新增或修改备注
export function feedback_bizCooperate_update(data) {
  return request({
    url: "/feedback/bizCooperate/update",
    method: 'put',
    params: data
  });
}

// ------------------------代理加盟-------------------------------
// GET /feedback/joinus/list查询商务合作列表
export function feedback_joinus_list(data) {
  return request({
    url: '/feedback/joinus/list',
    method: 'get',
    params: data
  });
}

// GET /feedback/joinus/{id}查询指定
export function feedback_joinus_detail(data) {
  return request({
    url: "/feedback/joinus/".concat(data.id),
    method: 'get',
    params: data
  });
}

// POST /feedback/joinus/{id}/updateMemo修改加盟信息的平台备注
export function feedback_joinus_updateMemo(data) {
  return request({
    url: "/feedback/joinus/".concat(data.id, "/updateMemo"),
    method: 'post',
    params: data
  });
}

// POST /feedback/joinus/{id}/updateStatus修改加盟信息状态
export function feedback_joinus_updateStatus(data) {
  return request({
    url: "/feedback/joinus/".concat(data.id, "/updateStatus"),
    method: 'post',
    params: data
  });
}

// PUT /feedback/joinus/update新增或修改备注
export function feedback_joinus_update(data) {
  return request({
    url: "/feedback/joinus/update",
    method: 'post',
    data: data
  });
}

// ------------------------意见反馈-------------------------------
// GET /feedback/newfeedback/list查询商务合作列表
export function feedback_newfeedback_list(data) {
  return request({
    url: '/feedback/newfeedback/list',
    method: 'get',
    params: data
  });
}

// GET /feedback/newfeedback/{id}查询指定
export function feedback_newfeedback_detail(data) {
  return request({
    url: "/feedback/newfeedback/".concat(data.id),
    method: 'get',
    params: data
  });
}

// POST /feedback/newfeedback/{id}/updateMemo修改加盟信息的平台备注
export function feedback_newfeedback_updateMemo(data) {
  return request({
    url: "/feedback/newfeedback/".concat(data.id, "/updateMemo"),
    method: 'post',
    params: data
  });
}

// PUT /feedback/newfeedback/update新增或修改备注
export function feedback_newfeedback_update(data) {
  return request({
    url: "/feedback/newfeedback/update",
    method: 'post',
    data: data
  });
}

// ------------------------使用账号申请-------------------------------
// GET /feedback/trialApply/list查询试用申请列表
export function feedback_trialApply_list(data) {
  return request({
    url: '/feedback/trialApply/list',
    method: 'get',
    params: data
  });
}

// GET /feedback/trialApply/{id}查询指定
export function feedback_trialApply_detail(data) {
  return request({
    url: "/feedback/trialApply/".concat(data.id),
    method: 'get',
    params: data
  });
}

// POST /feedback/trialApply/{id}/updateMemo修改加盟信息的平台备注
export function feedback_trialApply_updateMemo(data) {
  return request({
    url: "/feedback/trialApply/".concat(data.id, "/updateMemo"),
    method: 'post',
    params: data
  });
}

// PUT /feedback/trialApply/update新增或修改备注
export function feedback_trialApply_update(data) {
  return request({
    url: "/feedback/trialApply/updateMemo/".concat(data.id),
    method: 'post',
    params: data
  });
}

// PUT /feedback/trialApply/update新增或修改备注
export function feedback_trialApply_updateStatus(data) {
  return request({
    url: "/feedback/trialApply/updateStatus/".concat(data.id),
    method: 'post',
    params: data
  });
}