//
//
//
//
//
//
//
//
//
//

export default {
  name: 'AppMain',
  data: function data() {
    return {
      // key: new Date().getTime()
    };
  },
  computed: {
    key: function key() {
      return this.$route.path;
    }
  }
};