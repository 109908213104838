import request from '@/utils/request';
import axios from 'axios';
var qs = require('qs');
export function login(data) {
  return axios({
    url: process.env.VUE_APP_BASE_API + '/portal/login',
    method: 'post',
    data: data
  });
}
export function getRandcode(data) {
  return axios({
    url: process.env.VUE_APP_BASE_API + '/common/getRandcode',
    method: 'get',
    params: data
  });
}
export function logout(data) {
  return request({
    url: '/portal/logout',
    method: 'post',
    data: data
  });
}