var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            id: "progress-dialog",
            title: "",
            visible: _vm.progress.visible,
            "show-close": false,
            width: "166px",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.progress, "visible", $event)
            },
          },
        },
        [
          _c("el-progress", {
            staticStyle: { margin: "0 auto" },
            attrs: { type: "circle", percentage: _vm.progress.percent },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("keep-alive", [_c("router-view")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }