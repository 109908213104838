import { feedback_undoCount } from "@/api/feedback";
export default {
  namespaced: true,
  state: function state() {
    return {
      count: 0
    };
  },
  mutations: {
    SET_COUNT: function SET_COUNT(state, count) {
      state.count = count;
    }
  },
  actions: {
    get_count: function get_count(_ref) {
      var commit = _ref.commit,
        state = _ref.state;
      feedback_undoCount({}).then(function (res) {
        commit("SET_COUNT", res.data);
      });
    }
  }
};