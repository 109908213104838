import request from '@/utils/request';

/**
 * 
 * 出版商
 */
export var publisher = {
  list: function list(params) {
    return request({
      url: '/book/publisher/list',
      method: 'get',
      params: params
    });
  },
  allList: function allList(params) {
    return request({
      url: '/book/publisher/allList',
      method: 'get',
      params: params
    });
  },
  add: function add(data) {
    return request({
      url: '/book/publisher/add',
      method: 'put',
      data: data
    });
  },
  del: function del(id) {
    return request({
      url: "/book/publisher/".concat(id),
      method: 'delete'
    });
  }
};
export var books = {
  allBooks: function allBooks(params) {
    return request({
      url: '/book/list',
      method: 'get',
      params: params
    });
  },
  add: function add(data) {
    return request({
      url: '/book/add',
      method: 'post',
      data: data
    });
  },
  info: function info(id) {
    return request({
      url: "/book/".concat(id),
      method: 'get'
    });
  },
  count: function count(params) {
    return request({
      url: '/book/count',
      method: 'get',
      params: params
    });
  },
  appList: function appList(params) {
    return request({
      url: '/book/appList',
      method: 'get',
      params: params
    });
  },
  delBook: function delBook(id) {
    return request({
      url: "/book/".concat(id),
      method: 'delete'
    });
  }
};
export var version = {
  add: function add(data) {
    return request({
      url: '/book/version/add',
      method: 'post',
      data: data
    });
  },
  list: function list(params) {
    return request({
      url: '/book/version/list',
      method: 'get',
      params: params
    });
  },
  upDate: function upDate(id, data) {
    return request({
      url: "/book/version/".concat(id),
      method: 'post',
      data: data
    });
  },
  del: function del(id) {
    return request({
      url: "/book/version/".concat(id),
      method: 'delete'
    });
  },
  disable: function disable(id) {
    return request({
      url: "/book/version/disable/".concat(id),
      method: 'post'
    });
  },
  enable: function enable(id) {
    return request({
      url: "/book/version/enable/".concat(id),
      method: 'post'
    });
  }
};
export var suite = {
  list: function list(params) {
    return request({
      url: '/book/suite/list',
      method: 'get',
      params: params
    });
  },
  add: function add(data) {
    return request({
      url: '/book/suite/add',
      method: 'post',
      data: data
    });
  },
  update: function update(data) {
    return request({
      url: '/book/suite/update',
      method: 'post',
      data: data
    });
  },
  del: function del(id) {
    return request({
      url: "/book/suite/".concat(id),
      method: 'delete'
    });
  }
};
export var price = {
  list: function list(params) {
    return request({
      url: '/book/price/list',
      method: 'get',
      params: params
    });
  },
  add: function add(data) {
    return request({
      url: '/book/price/add',
      method: 'post',
      data: data
    });
  },
  upDate: function upDate(data) {
    return request({
      url: '/book/price/update',
      method: 'post',
      data: data
    });
  },
  del: function del(id) {
    return request({
      url: "/book/price/".concat(id),
      method: 'delete'
    });
  }
};
export var order = {
  list: function list(params) {
    return request({
      url: '/book/order/list',
      method: 'get',
      params: params
    });
  }
};
export var salesetting = {
  upDate: function upDate(id, data) {
    return request({
      url: "/book/salesetting/".concat(id),
      method: 'post',
      data: data
    });
  }
};
export var resource = {
  allList: function allList(params) {
    return request({
      url: '/book/resource/allList',
      method: 'get',
      params: params
    });
  },
  list: function list(params) {
    return request({
      url: '/book/resource/list',
      method: 'get',
      params: params
    });
  },
  del: function del(id) {
    return request({
      url: "/book/resource/".concat(id),
      method: 'delete'
    });
  },
  add: function add(data) {
    return request({
      url: '/book/resource/add',
      method: 'post',
      data: data
    });
  },
  update: function update(data) {
    return request({
      url: '/book/resource/update',
      method: 'post',
      data: data
    });
  },
  lock: function lock(id, data) {
    return request({
      url: '/book/resource/setting/' + id,
      method: 'put',
      params: data
    });
  }
};
export var activecode = {
  list: function list(id, params) {
    return request({
      url: "/book/activecode/batch/list/".concat(id),
      method: 'get',
      params: params
    });
  },
  add: function add(data) {
    return request({
      url: '/book/activecode/add',
      method: 'post',
      data: data
    });
  },
  export: function _export(id) {
    return request({
      url: "/book/activecode/export/".concat(id),
      method: 'get'
    });
  },
  mark: function mark(id, params) {
    return request({
      url: "/book/activecode/mark/".concat(id),
      method: 'post',
      params: params
    });
  },
  infoList: function infoList(id, params) {
    return request({
      url: "/book/activecode/".concat(id, "/list"),
      method: 'get',
      params: params
    });
  },
  del: function del(id) {
    return request({
      url: "/book/activecode/batch/".concat(id),
      method: 'delete'
    });
  }
};