import _objectSpread from "E:/vue/sdkxv2_manage/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import request from '@/utils/request';
// const qs = require("qs");

// 应用列表
export function app_list(data) {
  return request({
    url: '/app/list',
    method: 'get',
    params: data
  });
}
// 资源管理==所有应用列表
export function app_list_all(data) {
  return request({
    url: '/product/app/listAll',
    method: 'get',
    params: data
  });
}
// 创建应用
export function app_add(data) {
  return request({
    url: '/app/add',
    method: 'post',
    data: data
  });
}

// app已添加的平台
export function app_platform_list(data) {
  return request({
    url: '/app/platform/list',
    method: 'get',
    params: data
  });
}
// app已添加的平台
export function app_save(data) {
  var param = _objectSpread({}, data);
  delete param.pageLang;
  return request({
    url: '/app/save?pageLang=' + data.pageLang,
    method: 'post',
    data: param
  });
}

// app详情
export function app_detail(data) {
  return request({
    url: "/app/".concat(data.appId),
    method: 'get',
    params: data
  });
}

// app删除
export function app_remove(data) {
  return request({
    url: "/app/remove",
    method: 'delete',
    params: data
  });
}

// app拥有的语言
export function appLangList(data) {
  return request({
    url: "/app/appLangList",
    method: 'get',
    params: data
  });
}

// app语言删除
export function app_langRemove(data) {
  return request({
    url: "/app/langRemove",
    method: 'delete',
    params: data
  });
}

// app拥有平台
export function appPlatformList(data) {
  return request({
    url: "/school/osTypeList",
    method: 'get',
    params: data
  });
}