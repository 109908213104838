//
//
//
//
//
//
//
//
//
//
//
//

import { Navbar, AppMain } from "./components";
// import ResizeMixin from './mixin/ResizeHandler'

export default {
  name: 'Layout',
  components: {
    AppMain: AppMain,
    Navbar: Navbar
  },
  // mixins: [ResizeMixin],
  computed: {},
  methods: {}
};