var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar-container", class: { home: !_vm.show } },
    [
      _c("div", { staticClass: "navbar" }, [
        _c(
          "div",
          {
            staticClass: "sys",
            style: { marginRight: _vm.variables.commonPadding },
            on: {
              click: function ($event) {
                return _vm.jump("home")
              },
            },
          },
          [
            _c("img", {
              staticClass: "logo",
              attrs: { src: require("@/assets/logo.png"), alt: "" },
            }),
            _vm._v(" "),
            _c("div", [_vm._v("生动科学管理后台")]),
          ]
        ),
        _vm._v(" "),
        _vm.show
          ? _c("div", { staticClass: "nav_resource flex-auto" }, [
              _vm.menuList.indexOf("应用") > -1
                ? _c(
                    "a",
                    {
                      staticClass: "modules",
                      class: { active: _vm.module === "app" },
                      on: {
                        click: function ($event) {
                          return _vm.jump("app")
                        },
                      },
                    },
                    [_vm._v("应用")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.menuList.indexOf("资源") > -1
                ? _c(
                    "a",
                    {
                      staticClass: "modules",
                      class: { active: _vm.module === "resoure" },
                      on: {
                        click: function ($event) {
                          return _vm.jump("resoure")
                        },
                      },
                    },
                    [_vm._v("资源")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.menuList.indexOf("图书") > -1
                ? _c(
                    "a",
                    {
                      staticClass: "modules",
                      class: { active: _vm.module === "book" },
                      on: {
                        click: function ($event) {
                          return _vm.jump("book")
                        },
                      },
                    },
                    [_vm._v("图书")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.menuList.indexOf("渠道") > -1
                ? _c(
                    "a",
                    {
                      staticClass: "modules",
                      class: { active: _vm.module === "channel" },
                      on: {
                        click: function ($event) {
                          return _vm.jump("channel")
                        },
                      },
                    },
                    [_vm._v("渠道")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.menuList.indexOf("客户") > -1
                ? _c(
                    "a",
                    {
                      staticClass: "modules",
                      class: { active: _vm.module === "client" },
                      on: {
                        click: function ($event) {
                          return _vm.jump("client")
                        },
                      },
                    },
                    [_vm._v("客户")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.menuList.indexOf("分析") > -1
                ? _c(
                    "a",
                    {
                      staticClass: "modules",
                      class: { active: _vm.module === "analysis" },
                      on: {
                        click: function ($event) {
                          return _vm.jump("analysis")
                        },
                      },
                    },
                    [_vm._v("分析")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.menuList.indexOf("财务") > -1
                ? _c("a", { staticClass: "modules" }, [_vm._v("财务")])
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right-menu" },
          [
            _c(
              "el-button",
              {
                staticStyle: { "margin-right": "14px" },
                attrs: { type: "text" },
                on: {
                  click: function ($event) {
                    return _vm.jump("feedback")
                  },
                },
              },
              [
                _c("i", { staticClass: "el-icon-message-solid" }),
                _vm._v(" "),
                _vm.feedback.count
                  ? _c("span", { staticClass: "undo-count" }, [
                      _vm._v(_vm._s(_vm.feedback.count)),
                    ])
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _c(
              "el-dropdown",
              { staticClass: "avatar-container", attrs: { trigger: "click" } },
              [
                _c("div", { staticClass: "avatar-wrapper" }, [
                  _c("span", [_vm._v(_vm._s(_vm.user.user.realName))]),
                  _vm._v(" "),
                  _c("i", { staticClass: "el-icon-caret-bottom" }),
                ]),
                _vm._v(" "),
                _c(
                  "el-dropdown-menu",
                  {
                    staticClass: "user-dropdown",
                    attrs: { slot: "dropdown" },
                    slot: "dropdown",
                  },
                  [
                    _vm.menuList.indexOf("设置") > -1
                      ? _c(
                          "router-link",
                          { attrs: { to: "/setting" } },
                          [_c("el-dropdown-item", [_vm._v("设置")])],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-dropdown-item",
                      {
                        nativeOn: {
                          click: function ($event) {
                            return _vm.logout($event)
                          },
                        },
                      },
                      [
                        _c("span", { staticStyle: { display: "block" } }, [
                          _vm._v("退出"),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }