var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "nav_menu_tab" },
    [
      _c(
        "el-collapse",
        {
          class: { expand: _vm.expandTime },
          model: {
            value: _vm.activeNames,
            callback: function ($$v) {
              _vm.activeNames = $$v
            },
            expression: "activeNames",
          },
        },
        _vm._l(_vm.menuList, function (item, index) {
          return _c(
            "el-collapse-item",
            { key: index, attrs: { title: item.name, name: index } },
            _vm._l(item.children, function (children) {
              return _c(
                "div",
                {
                  key: children.label + children.value,
                  class: { selected: _vm.selectedCode == children.label },
                  on: {
                    click: function ($event) {
                      return _vm.handleChange(children)
                    },
                  },
                },
                [_vm._v(_vm._s(children.label))]
              )
            }),
            0
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }