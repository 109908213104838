import "E:\\vue\\sdkxv2_manage\\node_modules\\@vue\\babel-preset-app\\node_modules\\core-js\\modules\\es6.array.iterator.js";
import "E:\\vue\\sdkxv2_manage\\node_modules\\@vue\\babel-preset-app\\node_modules\\core-js\\modules\\es6.promise.js";
import "E:\\vue\\sdkxv2_manage\\node_modules\\@vue\\babel-preset-app\\node_modules\\core-js\\modules\\es6.object.assign.js";
import "E:\\vue\\sdkxv2_manage\\node_modules\\@vue\\babel-preset-app\\node_modules\\core-js\\modules\\es7.promise.finally.js";
import Vue from 'vue';
import App from "./App.vue";
import router from "./router";
import store from "./store";
import '@/styles/index.scss'; // global css
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "../fonts/iconfont.css";
import "./styles/element-variables.scss";
import navMenuTab from '@/components/navMenuTab';
import '@/permission'; // permission control
import Moment from 'moment';
// import VueSticky from 'vue-sticky' // Es6 module
import Sticky from 'vue-sticky-directive';
import NProgress from 'nprogress'; // progress bar
import VueUeditorWrap from 'vue-ueditor-wrap'; // ES6 Module

ElementUI.Dialog.props.closeOnClickModal.default = false; // 全局关闭点遮罩关闭弹框

Vue.prototype.$moment = Moment;
Vue.prototype.$NProgress = NProgress;
Vue.filter('comverTime', function (data, format) {
  return Moment(data).format(format);
});

// Vue.use(ElementUI, { size: 'mini', zIndex: 3000 })
Vue.use(ElementUI);
Vue.use(Sticky);
Vue.component('navMenuTab', navMenuTab);
Vue.component('vue-ueditor-wrap', VueUeditorWrap);

// Vue.directive('sticky', VueSticky)
Vue.config.productionTip = false;
new Vue({
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
}).$mount('#app');